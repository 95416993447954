<template>
    <v-app id="iconvu" :class="`${!$vuetify.breakpoint.smAndDown ? 'full-sidebar' : 'mini-sidebar'}`">
        <v-container v-if="loading">
            <v-overlay color="White">
                <v-progress-circular indeterminate size="30" color="primary">
                </v-progress-circular>
            </v-overlay>
        </v-container>

        <router-view  v-else/>
    </v-app>

</template>

<script>
    //import axios from 'axios';
export default {
  name: 'App',
        metaInfo: {
            title: 'Iconvu.com',
            titleTemplate: '%s | Tools',
            htmlAttrs: {
                lang: 'en-US'
            },
            meta: [
                { charset: 'utf-8' },
                { name: 'description', content: 'software tools website, your one-stop destination for a wide range of essential tools to enhance your productivity and streamline your workflows' },
                { name: 'viewport', content: 'width=device-width, initial-scale=1' },
                { name: 'keywords', content: 'software, tools, website,code,program' },
                { name: 'robots', content: 'index, follow' },
                { name: 'language', content: 'English' }
            ]
        },
  components: {
    
        },
        data: () => ({
            loading: false
        }),
        created() {
            //axios.interceptors.request.use(
            //    (config) => {
            //        this.loading = true;
            //        return config;
            //    },
            //    (error) => {
            //        // trigger
            //        this.loading = false;
            //        return Promise.reject(error);
            //    }
            //);
            //axios.interceptors.response.use(
            //    (response) => {
            //        this.loading = false;
            //        return response;
            //    },
            //    (error) => {
            //        this.loading = false;
            //        return Promise.reject(error);
            //    }
            //);
            this.checkIP()
        },
        methods: {
            async checkIP() {
                this.loading = true 
               
                await this.$http.get('https://api.ipify.org?format=json')
                    .then(response => {
                     //   console.log(response.data)
                      //  console.log(process.env.VUE_APP_ROOT_API)
                        let  params = {
                            DomainName: "Iconvu.com",
                            IpAddress: response.data.ip
                        }
                        console.log(JSON.stringify(params))
                        this.$http.post(process.env.VUE_APP_ROOT_API + '/goGreen/saveHits', JSON.stringify(params), {
                            headers: {
                                "Content-Type": "application/json",
                                "Accept":"application/json"
                            }
                        })
                            .then()
                            .catch(error => { console.log(error) })
                            .finally(() => (this.loading = false)) 
                    })
                    .catch(error => { console.log(error) }) 
                    .finally(() => (this.loading = false)) 
            }
        }

};
</script>

